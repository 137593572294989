import { UserManager, WebStorageStateStore } from 'oidc-client'

import {
  IDENTITY_SERVER_URL,
  IDENTITY_CLIENTID,
  API_NAME,
  APP_URL
} from '@/config'

class AuthService {
  userManager = null

  constructor() {
    const settings = {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      authority: IDENTITY_SERVER_URL,
      client_id: IDENTITY_CLIENTID,
      redirect_uri: `${APP_URL}callback.html`,
      automaticSilentRenew: true,
      silent_redirect_uri: `${APP_URL}silent-renew.html`,
      response_type: 'code',
      scope: `openid profile ${API_NAME}`,
      post_logout_redirect_uri: `${APP_URL}logout`,
      filterProtocolClaims: true,
      loadUserInfo: true
    }

    this.userManager = new UserManager(settings)
  }

  getAccessToken() {
    return this.userManager.getUser().then(data => {
      if (data) {
        return data.access_token
      }
      return null
    })
  }

  getUser() {
    return this.userManager.getUser().then(user => {
      return user
    })
  }

  login() {
    return this.userManager.signinRedirect()
  }

  logout() {
    return this.userManager.signoutRedirect()
  }

  refresh(route) {
    return this.userManager.signinSilent(route)
  }

  async isInRole(role) {
    const user = await this.getUser()
    return this.isUserInRole(user, role)
  }

  isUserInRole(user, role) {
    return user && user.profile.role.indexOf(role) >= 0
  }

  signinRedirectCallback() {
    return this.userManager.signinRedirectCallback()
  }
}

export default new AuthService()
