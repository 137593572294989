//
// BEMA Theme
//
const bemaTheme =  {
  name: 'bema',
  title: 'BEMA',
  src: 'bema/logo.png',
  favicon: 'bema-favicon.png',
  location: {
    latitude: 13.650718860017589,
    longitude: 2.058192752301693,
    mgrs: '31P DQ 557 733'
  }
}

export default bemaTheme
