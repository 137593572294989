import apiClient from '@/services/api/clients/api-client.js'
import { API_URL } from '@/config'

const path = `${API_URL}/collection`

const client = apiClient.build(path)

client.archive = id => {
  const archivePath = `${client.__api.entityPath(path, id)}/archive`
  return client.__api.put(archivePath).then(response => response.data)
}

client.restore = id => {
  const archivePath = `${client.__api.entityPath(path, id)}/restore`
  return client.__api.put(archivePath).then(response => response.data)
}

client.export = id => {
  const exportPath = `${client.__api.entityPath(path, id)}/exports`
  return client.__api.put(exportPath).then(response => response.data)
}

client.exports = id => {
  const exportPath = `${client.__api.entityPath(path, id)}/exports`
  return client.__api.get(exportPath).then(response => response.data)
}

client.merge = model => {
  const mergePath = `${path}/merge`
  return client.__api.post(mergePath, model).then(response => response.data)
}

export default client
