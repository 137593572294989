<template>
  <div class="d-flex flex-column field-card">
    <label class="text-caption"> {{ label }}</label>
    <div class="border-bottom-1">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: () => 'default'
    }
  },
  data(){
    return {

    }
  }
}
</script>

<style scoped>
.field-card{
  border-bottom: 1px solid #ccc;
  padding-top: 12px;
  margin-top: 4px;
}
</style>
