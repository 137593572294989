<template>
  <v-container class="grow d-flex" fluid>
    <v-overlay v-if="loading" absolute color="blue-grey lighten-5">
      <v-progress-circular indeterminate size="64" color="primary" />
    </v-overlay>

    <v-row no-gutters>
      <v-col
        cols="12"
        md="3"
        class="order-last order-md-first text-no-wrap mr-0"
      >
        <v-card flat class="mr-2" v-if="!loading">
          <v-toolbar flat>
            <v-toolbar-title>{{ $t('collections') }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="selectable = !selectable">
              <v-icon v-if="!selectable">mdi-chevron-down</v-icon>
              <v-icon v-if="selectable">mdi-chevron-up</v-icon>
            </v-btn>
          </v-toolbar>
          <v-toolbar flat dense v-if="selectable" color="grey lighten-4">
            <v-toolbar-title></v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn small icon @click="merge">
              <v-icon>mdi-merge</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <v-list :height="height" class="overflow-auto">
            <v-list-item-group v-model="selectedItem">
              <v-list-item v-for="(item, i) in items" :key="i">
                <template v-slot:default="{ toggle }">
                  <v-list-item-action v-if="selectable" class="mr-4 mt-0">
                    <v-checkbox
                      v-model="selectedCollections"
                      :multiple="true"
                      color="primary"
                      label=""
                      :value="item.id"
                      @click="toggle"
                      hide-details
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ i + 1 }}. {{ item.name }}</v-list-item-title
                    >
                    <v-list-item-subtitle>{{
                      item.collectionNumber
                    }}</v-list-item-subtitle>
                    <v-list-item-subtitle
                      >Event Date:
                      {{
                        item.eventDateTime | dateFormat
                      }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
      <v-col cols="12" md="9">
        <v-card :height="height" flat color="transparent">
          <div ref="googleMap" id="map"></div>
          <v-card-actions v-if="!loading" class="d-none d-sm-block">
            <v-row dense>
              <v-col cols="2" class="text-no-wrap">{{
                this.minDate | dateFormat(DateTime.DATE_MED)
              }}</v-col>
              <v-spacer></v-spacer>
              <v-col cols="8">
                <v-slider
                  v-model="dateRange"
                  min="0"
                  :max="sliderMax"
                  dense
                  hide-details="true"
                ></v-slider>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="2" class="text-no-wrap">{{
                this.maxDate | dateFormat(DateTime.DATE_MED)
              }}</v-col>
            </v-row>
            <v-row no-gutters>
              <v-col class="text-center">
                {{ selectedDate | dateFormat }}
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <div v-if="items.length > 0" style="position:absolute;left:-1000px;top:0px">
      <div v-for="item in items" :key="item.id" :ref="item.id">
        <v-card max-width="344">
          <v-card-text>
            <h3>{{ item.name }}</h3>
            <div>{{ item.eventDateTime | dateFormat }}</div>
            <p>{{ item.collectionNumber }}</p>
            <div class="text--primary">
              {{ item.description }}
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn
              link
              plain
              color="blue darken-1"
              small
              target="_blank"
              :to="{
                name: 'collection-detail',
                params: { collectionId: item.id }
              }"
            >
              View More
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
import _ from 'lodash'
import { DateTime } from 'luxon'
import { current, format, parse } from '@/services/datetime/index'
import searchApi from '@/services/api/collection-search'
import locationService from '@/services/location'
import { MarkerClusterer } from '@googlemaps/markerclusterer'

export default {
  data() {
    return {
      googleMaps: null,
      infoWindow: null,
      loading: true,
      map: null,
      markerCluster: null,
      browserLocation: null,
      selectedCollections: [],
      selectable: false,
      crumbs: [
        { text: 'Collections', to: { name: 'collections-index' } },
        { text: 'Maps', to: { name: 'maps-index' } }
      ],
      items: [],
      selectedItem: null,
      dateRange: 50,
      bounds: null,
      minDate: null,
      maxDate: current.now().endOf('day'),
      markers: [],
      DateTime: DateTime
    }
  },
  computed: {
    mapOptions() {
      const defaultLocation = locationService.defaultLocation()

      const center = this.browserLocation
        ? {
            lat: this.browserLocation.latitude,
            lng: this.browserLocation.longitude
          }
        : {
          lat: defaultLocation.latitude,
          lng: defaultLocation.longitude
        }

      if (this.$route.params.latitude && this.$route.params.longitude) {
        center.lat = parseFloat(this.$route.params.latitude)
        center.lng = parseFloat(this.$route.params.longitude)
      }

      const options = {
        center: center,
        zoom: locationService.zoom,
        fullscreenControl: false,
        streetViewControl: false
      }

      return options
    },
    height() {
      return this.$vuetify.breakpoint.xs ? '40vh' : '75vh'
    },
    minDateLabel() {
      return format.toLocal(this.minDate, DateTime.DATE_MED)
    },
    selectedDate() {
      if (this.minDate) {
        return this.minDate.plus({ days: this.dateRange })
      }
      return null
    },
    sliderMax() {
      if (this.minDate) {
        return this.maxDate.diff(this.minDate, ['days']).days
      }
      return 30
    },
    canMerge() {
      return this.selectable && this.selectedCollections.length > 1
    }
  },
  watch: {
    selectedDate(val) {
      //Not sure why I have to store the markers in a separate array in order for the map to updated but it's the only way I could get it to work
      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].setVisible(false)
      }
      for (let i = 0; i < this.items.length; i++) {
        this.items[i].marker.setVisible(this.items[i].eventDateTime <= val)
      }
    },
    selectedItem(val) {
      this.showInfoWindow(this.items[val])
    }
  },
  mounted() {
    this.initializeMap()
  },
  methods: {
    async initializeMap() {
      if (this.map) {
        this.loading = false
        return
      }

      this.browserLocation = await locationService.browserLocation()

      this.googleMaps = await locationService.googleMaps()

      this.map = new this.googleMaps.Map(
        document.getElementById('map'),
        this.mapOptions
      )

      this.infoWindow = new this.googleMaps.InfoWindow({
        content: '<div id="content">placeholder</div>'
      })

      this.infoWindow.addListener('closeclick', () => {
        this.selectedItem = null
      })

      this.map.addListener('bounds_changed', () => {
        this.onBoundsChanged()
      })

      this.loading = false
    },
    onBoundsChanged: _.debounce(async function() {
      _.forEach(this.markers, m => {
        m.setMap(null)
      })
      this.markers = []

      const response = await searchApi.bounds(this.map.getBounds())
      this.items = response.data.results

      for (let i = 0; i < this.items.length; i++) {
        this.items[i].eventDateTime = parse.asDateTime(
          this.items[i].eventDateTime
        )
        this.items[i].marker = new this.googleMaps.Marker({
          position: {
            lat: this.items[i].latitude,
            lng: this.items[i].longitude
          },
          map: this.map,
          title: this.items[i].collectionNumber,
          label: `${i + 1}`
        })

        this.items[i].marker.setVisible(true)
        this.markers.push(this.items[i].marker)

        this.items[i].marker.addListener('click', () => {
          this.selectedItem = i
        })
      }

      this.markerCluster = new MarkerClusterer(this.map, this.markers, {
        imagePath: `${locationService.imagePath}/m`,
        ignoreHidden: true,
        maxZoom: locationService.zoom - 2
      })

      this.minDate = parse
        .asDateTime(this.items[0].eventDateTime)
        .plus({ days: -1 })
        .endOf('day')
      this.maxDate = parse
        .asDateTime(this.items[this.items.length - 1].eventDateTime)
        .plus({ days: 1 })
        .endOf('day')
      this.dateRange = this.sliderMax

      if (this.selectedItem != null) {
        this.showInfoWindow(this.items[this.selectedItem])
      }
    }, 1000),
    showInfoWindow(item) {
      if (item == null) {
        this.infoWindow.close()
        return
      }

      item.marker.setVisible(true)
      const content = this.$refs[item.id][0]
      this.infoWindow.setContent(content.innerHTML)
      this.infoWindow.open(this.map, item.marker)
    },
    merge() {
      if (this.canMerge) {
        console.log('selectedCollections', this.selectedCollections)
        this.$router.push({
          name: 'collections-merge',
          query: { ids: this.selectedCollections }
        })
      }
    }
  }
}
</script>

<style>
#map {
  width: 100%;
  height: 92%;
}
</style>
