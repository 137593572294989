export default {
  namespaced: true,
  state: {
    errors: [],
    messages: []
  },
  mutations: {
    addMessage(state, payload) {
      state.messages.push(payload.message)
    },
    addError(state, payload) {
      state.errors.push(payload)
    },
    clearErrors(state) {
      state.errors = []
    },
    clearMessages(state) {
      state.messages = []
    },
    removeMessage(state, payload) {
      if (state.messages && state.messages.indexOf(payload.index)) {
        state.messages.splice(payload.index, 1)
      }
    }
  },
  actions: {
    addError({ commit }, payload) {
      commit('addError', payload)
    },
    addMessage({ commit }, payload) {
      commit('addMessage', payload)
    },
    clearErrors({ commit }) {
      commit('clearErrors')
    },
    clearMessages({ commit }) {
      commit('clearMessages')
    },
    removeMessage({ commit }, payload) {
      commit('removeMessage', payload)
    }
  },
  getters: {
    errors: state => {
      return state.errors
    },
    lastError: state => {
      if (state.errors.length > 0) {
        return state.errors[state.errors.length - 1]
      }
      return null
    },
    messages: state => {
      return state.messages
    }
  }
}
