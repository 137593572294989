<template>
  <div v-if="model">
    <app-toolbar :crumbs="crumbs">
      <v-spacer></v-spacer>
      <v-btn v-if="model.fileHasBeenReceived" plain>
        <v-icon
          class="mr-2"
          @click.stop="download()"
          :title="$t('downloadPackageFile')"
          >mdi-download</v-icon
        >
      </v-btn>

      <v-menu bottom left offset-y pa-3>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item-group>
            <v-list-item
              v-if="model.status != 'Created'"
              @click.stop="process()"
            >
              <v-list-item-content>
                <v-list-item-title>Process</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </app-toolbar>
    <v-card flat width="100%">
      <v-list-item three-line>
        <v-list-item-content>
          <v-list-item-title
            v-if="model.fileHasBeenReceived"
            class="headline mb-1"
          >
            {{ model.status }}
          </v-list-item-title>
          <v-list-item-title v-if="!model.fileHasBeenReceived">
            <span class="headline mb-1">{{ $t('waiting') }}</span>
            <span class="font-small"> {{ $t('packageNotUploaded') }}</span>
          </v-list-item-title>
          <div v-if="!model.collectionId" class="mt-2">
            {{ $t('collectionNumber') }}: {{ model.collectionNumber }}
          </div>
          <div v-if="model.collectionId" class="mt-2">
            {{ $t('collection') }}:
            <router-link
              :to="{
                name: 'collection-detail',
                params: { collectionId: model.collectionId }
              }"
            >
              {{ model.collectionNumber }}
            </router-link>
          </div>
          <div class="mt-2">
            {{ $t('created') }}: {{ model.createdDateTime | dateFormat }}
            {{ $t('by') }}
            {{ model.uploadedByUserName }}
          </div>
          <div class="mt-2">
            {{ $t('uploadedDate') }}: {{ model.createdDateTime | dateFormat }}
          </div>
        </v-list-item-content>
      </v-list-item>
    </v-card>

    <h3>{{ $t('eventLog') }}</h3>
    <event-log-table :items="model.eventLog" />

    <v-overlay :value="processing">
      <v-alert dense color="primary" class="text-center" min-width="400px">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
        <div class="pt-2">
          {{ $t('packageProcessing') }}
          <p>{{ $t('navigateDoNot') }}</p>
        </div>
      </v-alert>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import packageApi from '@/services/api/package'
import appToolbar from '@/components/layout/app-toolbar'
import eventLogTable from '@/components/event-logs/table'

export default {
  components: {
    appToolbar,
    eventLogTable
  },
  data() {
    return {
      model: null,
      processing: false
    }
  },
  computed: {
    id() {
      return this.$route.params.packageId
    },
    crumbs() {
      return [
        {
          text: this.$t('packages'),
          to: { name: 'packages-index' }
        },
        {
          text: this.model.collectionNumber,
          disabled: true
        }
      ]
    }
  },
  watch: {
    id(id) {
      this.load(id)
    }
  },
  mounted() {
    if (this.id) {
      this.load(this.id)
    }
  },
  methods: {
    ...mapActions(['setTitle']),
    ...mapActions('messages', ['addError', 'addMessage']),
    async load(id) {
      if (!id) {
        return
      }
      this.model = await packageApi.fetch(id)
    },
    async download() {
      try {
        const url = await packageApi.downloadUrl(this.id)
        window.open(url)
      } catch (error) {
        console.log('error', error)
        this.addError({ message: this.$t('packageError'), error })
      }
    },
    async process() {
      try {
        this.processing = true

        await packageApi.process(this.id)

        this.addMessage({
          message: this.$t('packageProcessed'),
          type: 'success'
        })

        await this.load(this.id)
      } catch (e) {
        this.addError(e)
      } finally {
        this.processing = false
      }
    }
  }
}
</script>
