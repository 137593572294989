import Index from '@/views/package/index.vue'
import Upload from '@/views/package/upload.vue'
import Detail from '@/views/package/detail.vue'

export default [
  {
    path: '/packages',
    name: 'packages-index',
    component: Index,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/packages/upload',
    name: 'package-upload',
    component: Upload,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/packages/:packageId',
    name: 'package-detail',
    component: Detail,
    meta: {
      authenticated: true
    }
  }
]
