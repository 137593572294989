<template>
  <div class="text-center">
    <v-icon class="mr-2" color="primary" size="48"
      >mdi-file-document-outline</v-icon
    >
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: Number,
      default: 150
    },
    width: {
      default: '100%'
    },
    maxHeight: { default: '75px' },
    maxWidth: { default: null },
    value: {
      type: null,
      default: ''
    }
  },
  data() {
    return {
      innerValue: '',
      loading: false
    }
  }
}
</script>
