<template>
  <div class="pt-2">
    <img :src="dataUrl" :alt="value.name" :title="value.name" width="100%" />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: null,
      default: ''
    }
  },
  computed: {
    dataUrl() {
      if (this.value.dataUrl) {
        return this.value.dataUrl
      }
      return null
    }
  }
}
</script>
