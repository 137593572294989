<template>
  <div>
    <app-toolbar :crumbs="crumbs" />

    <v-alert v-if="!fileIsValid" dense type="error">
      <v-row align="center">
        <v-col class="grow">{{ fileErrorMessage }}</v-col>
        <v-col class="shrink">
          <v-btn color="white" @click="clear" outlined>
            {{ $t('tryAgain') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <v-overlay :value="uploading">
      <v-alert dense color="primary" class="text-center" min-width="400px">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
        <div class="pt-2">
          {{ $t('packageUploading') }}
          <p>{{ $t('navigateDoNot') }}</p>
          <p class="text-caption">{{ $t('uploadLargeMsg') }}</p>
        </div>
      </v-alert>
    </v-overlay>

    <v-card
      v-if="!hasFile && fileIsValid"
      class="d-flex justify-center mb-6"
      flat
      tile
    >
      <v-card
        :color="color"
        hover
        @dragover="dragging = true"
        @drop="dragging = false"
        @dragleave="dragging = false"
        max-width="600px"
        class="flex"
        :class="['upload-box', dragging ? 'upload-box-active' : '']"
      >
        <form enctype="multipart/form-data" novalidate>
          <input
            type="file"
            :name="uploadFieldName"
            :disabled="uploading"
            @change="filesChange($event.target.files)"
            accept=".zip,.mctpkg"
            class="input-file"
          />
          <v-row v-if="!uploading" justify="center" class="fill-height pt-8">
            <div class="pa-3 ">
              <v-icon x-large>mdi-cloud-upload</v-icon>
            </div>
            <div class="pa-3 text-center ">
              <small>
                {{ $t('packageExplorer') }}
              </small>
            </div>
          </v-row>
        </form>
      </v-card>
    </v-card>

    <v-form v-if="hasFile && manifest" ref="form" v-model="formIsValid">
      <v-alert dense type="success">
        {{ $t('packageDefaultData') }}
        {{ contents.files.length }} {{ $t('files') }}
      </v-alert>
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                :label="`${$t('collectionNumber')}*`"
                v-model="model.collectionNumber"
                :readonly="true"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                :label="$t('trackingId')"
                v-model="model.trackingId"
                :readonly="true"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn plain color="default" @click="cancel">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            :disabled="!valid"
            tile
            large
            color="primary"
            @click.once="save"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import appToolbar from '@/components/layout/app-toolbar'
import packageApi from '@/services/api/package'
import { fileUtils } from '@/services/file-utils.service'

import jsZip from 'jszip'

export default {
  components: {
    appToolbar
  },
  data() {
    return {
      crumbs: [
        { text: this.$t('packagesUploaded'), to: { name: 'packages-index' } },
        { text: this.$t('upload'), disabled: true }
      ],
      model: {},
      dragging: false,
      uploadFieldName: 'files',
      file: null,
      fileErrorMessage: '',
      fileIsValid: true,
      formIsValid: true,
      parsing: false,
      uploading: false,
      contents: {
        files: []
      },
      manifest: null
    }
  },
  computed: {
    color() {
      return this.dragging ? 'green lighten-4' : 'grey lighten-3'
    },
    hasFile() {
      return this.file !== null
    },
    valid() {
      return this.formIsValid && this.hasFile && this.manifest
    },
    manifestIsMissingOrInvalid() {
      return this.hasFile && !this.manifest
    }
  },
  methods: {
    ...mapActions('messages', ['addMessage', 'addError']),
    cancel() {
      this.$router.push({ name: 'packages-index' })
    },
    clear() {
      this.manifest = null
      this.file = null
      this.fileIsValid = true
      this.fileErrorMessage = ''
    },
    async save() {
      try {
        this.uploading = true
        const uploadUrl = await packageApi.uploadUrl(this.model)
        if (uploadUrl) {
          await packageApi.upload(uploadUrl, this.file)
        } else {
          await packageApi.uploadDirect(this.model, this.file)
        }

        this.addMessage({
          message: this.$t('packageUploaded'),
          type: 'success'
        })

        this.$router.push({ name: 'packages-index' })
      } catch (e) {
        this.addError(e)
      } finally {
        this.uploading = false
      }
    },
    async filesChange(files) {
      try {
        this.parsing = true
        this.clear()

        const file = files[0]

        if (!fileUtils.isValidUploadSize(file)) {
          this.addFileError(
            `File size must be less than ${
              fileUtils.maxUploadSize
            }MB but file size is ${fileUtils.fileBytesToMB(
              file
            )}MB Please select a smaller file.`
          )
          return
        }

        jsZip
          .loadAsync(file)
          .then(async zip => {
            zip.forEach(async (relativePath, file) => {
              if (relativePath.endsWith('.json') && !this.manifest) {
                const manifestFile = await file.async('string')

                this.manifest = JSON.parse(manifestFile)
                this.model.collectionNumber = this.manifest.collectionNumber
                this.model.trackingId = this.manifest.trackingId
              }
              this.contents.files.push({
                name: relativePath,
                size: file.size
              })
            })

            this.file = file
          })
          .catch(e => {
            this.addFileError(`Could not parse package file: ${e.message}`)
          })
      } catch (e) {
        this.addFileError(`Could not load package file ${e.message}`)
      } finally {
        this.parsing = false
      }
    },
    addFileError(message) {
      this.fileIsValid = false
      this.fileErrorMessage = message
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-box {
  outline: 1px dashed grey;
  /* the dash box */
  min-height: 150px;
  /* minimum height */
  position: relative;
  cursor: pointer;
}

//deep//
form {
  min-height: 150px;
}

//deep//
.input-file {
  opacity: 0;
  /* invisible but it's there! */
  width: 100%;
  height: 100%;
  min-height: 150px;
  position: absolute;
  cursor: pointer;
}
</style>
