import personSearch from '@/services/api/person'

export default {
  namespaced: true,
  state: {
    meta: null,
    results: [],
    selected: null,
    term: null
  },
  mutations: {
    select(state, payload) {
      state.selected = payload.selected
    },
    setTerm(state, term) {
      state.term = term
    },
    setResults(state, payload) {
      state.meta = payload.meta
      state.results = payload.results
      //state.term = payload.term
    },
    clear(state) {
      state.meta = null
      state.results = []
      state.term = null
    }
  },
  actions: {
    clear({ commit }) {
      commit('clear')
    },
    select({ commit }, payload) {
      commit('select', payload)
    },
    setTerm({ commit }, payload) {
      commit('setTerm', payload.term)
    },
    async search({ commit }, payload) {
      const response = await personSearch.search(payload)
      commit('setResults', response)

      return response.data
    },
    async all({ commit }, payload) {
      const response = await personSearch.all(payload)
      commit('setResults', response)

      return response.data
    }
  },
  getters: {
    meta: state => {
      return state.meta
    },
    results: state => {
      return state.results
    },
    term: state => {
      return state.term
    },
    selected: state => {
      return state.selected
    }
  }
}
