<template>
  <v-dialog v-model="dialog" class="modal" max-width="500px" persistent>
    <v-form v-if="model" v-model="valid" ref="form">
      <v-card>
        <v-card-title>
          {{ $t('drawingSave') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                :label="`${$t('name')}*`"
                v-model="model.name"
                :rules="nameRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                name="input-7-1"
                :label="`${$t('description')}*`"
                auto-grow
                v-model="model.description"
                :rules="descriptionRules"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn plain color="default" @click="cancel">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn tile large color="primary" @click="save" :disabled="!valid">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import _ from 'lodash'
export default {
  props: {
    value: { type: Boolean, default: false },
    drawing: { type: Object, required: true }
  },
  data() {
    return {
      dialog: false,
      model: {},
      nameRules: [
        v => !!v || this.$t('nameRequired'),
        v => (v && v.length <= 250) || 'Name must be less than 250 characters'
      ],
      descriptionRules: [
        v => !!v || this.$t('descriptionRequired'),
        v => (v && v.length <= 5000) || this.$t('descriptionLimit')
      ],
      valid: true
    }
  },
  mounted() {
    this.model = _.cloneDeep(this.value)
  },
  watch: {
    dialog(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.dialog = val
    },
    drawing(newVal) {
      if (newVal) {
        this.model = _.cloneDeep(newVal)
      } else {
        this.model = {}
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    save() {
      if (this.valid) {
        this.$emit('save', this.model)
      }
    }
  }
}
</script>
