export default (value, limit, suffix) => {
  if (!value) return ''

  suffix = suffix || ''

  value = value.toString()
  if (value.length > limit) {
    return value.substring(0, limit) + suffix
  } else {
    return value
  }
}
