<template>
  <div>
    <app-toolbar :crumbs="crumbs" />

    <collection-form :item="model" @cancel="onCancel" @save="onSave" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import appToolbar from '@/components/layout/app-toolbar'
import collectionApi from '@/services/api/collection'
import collectionForm from './form'

export default {
  components: {
    appToolbar,
    collectionForm
  },
  data() {
    return {
      crumbs: [
        { text: this.$t('collections'), to: { name: 'collections-index' } },
        { text: this.$t('edit'), disabled: true }
      ],
      model: {}
    }
  },
  computed: {
    ...mapGetters('messages', ['errors']),
    id() {
      return this.$route.params.collectionId
    }
  },
  async mounted() {
    this.model = await collectionApi.fetch(this.id)
  },
  methods: {
    onCancel() {
      this.$router.go(-1)
    },
    async onSave(model) {
      await collectionApi.put(model.id, model)
      this.$router.push({
        name: 'collection-detail',
        params: { collectionId: model.id }
      })
    }
  }
}
</script>

<style></style>
