<template>
  <div>
    <v-dialog :value="drawingDialog" fullscreen persistent>
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-btn icon dark @click="onExit">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{
            model.name || $t('drawingAdd')
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <confirm-dialog
              v-if="allowDelete"
              :title="$t('drawingDelete')"
              :text="$t('drawingDeleteConfirm')"
              @confirm="onDelete"
            ></confirm-dialog>
            <v-btn dark text @click="saveDialog = !saveDialog">
              {{ $t('save') }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div id="editor-node" ref="editor"></div>
      </v-card>
    </v-dialog>

    <close-confirm v-model="confirm" @close="close" @cancel="cancel">
    </close-confirm>

    <drawings-form
      v-model="saveDialog"
      :drawing="model"
      @save="onSave"
      @cancel="cancel"
    ></drawings-form>
    <save-spinner :start="saveSpinner"> </save-spinner>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions } from 'vuex'
import collectionApi from '@/services/api/collection'
import drawingsApi from '@/services/api/drawings'
import { SPD_SERVER_URL, SPD_LICENSE_ID } from '@/config'
import collectionMixin from '@/components/mixins/collection'
import profileMixin from '@/components/mixins/profile'
import confirmDialog from '@/components/dialogs/confirmation'
import saveSpinner from '@/components/mixins/spinner'
import closeConfirm from './close-confirm'
import drawingsForm from './form'

export default {
  mixins: [collectionMixin, profileMixin],
  components: { confirmDialog, saveSpinner, closeConfirm, drawingsForm },
  props: {
    value: { type: Object, required: true },
    drawingDialog: { default: false }
  },
  data() {
    return {
      editor: null,
      model: {},

      confirm: false,
      drawingsApi: null,
      isDiagramDirty: false,
      saveDialog: false,
      saveSpinner: false,
      nameRules: [
        v => !!v || this.$t('nameRequired'),
        v => (v && v.length <= 250) || this.$t('nameLimit')
      ],
      descriptionRules: [
        v => !!v || this.$t('descriptionRequired'),
        v => (v && v.length <= 5000) || this.$t('descriptionLimit')
      ],
      valid: true
    }
  },
  computed: {
    collectionId() {
      return this.$route.params.collectionId
    },
    crumbs() {
      const items = [].concat(this.baseCrumbs)

      items.push({ text: this.$t('drawingNew'), disabled: true })

      return items
    },
    allowDelete() {
      return this.model.id
    }
  },
  async mounted() {
    this.drawingsApi = drawingsApi.build(this.collectionId)
    this.collection = await collectionApi.fetch(this.collectionId)
    this.isLoading = false
    this.model = _.cloneDeep(this.value)
  },
  watch: {
    drawingDialog(val) {
      if (val) {
        setTimeout(() => {
          this.loadEditor()
        }, 200)
      }
    },
    value(val) {
      if (val) {
        this.model = _.cloneDeep(val)
      } else {
        this.model = { blank: true }
      }
    }
  },
  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy()
      this.editor = null
    }
  },
  methods: {
    ...mapActions('messages', ['addMessage']),
    loadEditor() {
      if (!window.__editor) {
        setTimeout(() => {
          this.loadEditor()
        }, 200)
        return
      }

      if (!this.editor) {
        const el = this.$refs.editor

        this.editor = new window.__editor(undefined, el)
        this.editor.startup({
          serviceUrl: `${SPD_SERVER_URL}/REST`,
          licenseId: SPD_LICENSE_ID,
          userId: this.userId
        })

        this.editor.on('diagramDirty', e => {
          this.isDiagramDirty = e
        })

        el.style.height = `${el.parentElement.clientHeight}px`
        el.style.width = el.parentElement.clientWidth
      } else {
        this.editor.eraseDiagram()
      }
      if (this.model.svg) {
        this.editor.load(
          this.model.svg,
          () => {},
          error => {
            alert(error)
          }
        )
      }

      this.editor.show()
    },
    async onSave(model) {
      this.saveDialog = false
      this.saveSpinner = true

      this.model.name = model.name
      this.model.description = model.description

      this.editor.store(
        {
          image: true,
          format: 'image/png',
          margin: 0,
          width: 600,
          height: 450,
          imageQuality: 0.9
        },

        async (diagram, diagramInfo) => {
          let action = 'save'
          let actionMessage = 'added'

          this.model.svg = diagram

          if (!this.model.id) {
            const response = await this.drawingsApi.post(this.model)
            this.model = response.data
            action = 'save'
          } else {
            await this.drawingsApi.put(this.model.id, this.model)

            actionMessage = 'updated'
            action = 'update'
          }

          const image = await this.drawingsApi.uploadImage(
            this.model.id,
            diagramInfo.image
          )
          this.model.image = image

          this.addMessage({
            message: `${this.$t('drawing')} '${this.model.name}' ${this.$t('hasBeen')} ${actionMessage}.`
          })
          this.$emit(action, this.model)
          this.saveSpinner = false
          this.close()
        },
        error => {
          this.saveSpinner = false
          console.log(error)
        }
      )
    },
    async onDelete() {
      await this.drawingsApi.delete(this.model.id)

      this.$emit('delete', this.model)
      this.addMessage({
        message: `${this.$t('drawing')} '${this.model.name}' ${this.$t('hasBeenDeleted')}`
      })
      this.close()
    },
    onExit() {
      if (this.isDiagramDirty) {
        this.confirm = true
      } else {
        this.close()
      }
    },
    close() {
      this.confirm = false
      this.saveDialog = false
      this.$emit('update:drawingDialog', false)
      if (this.editor) {
        this.editor.hide()
      }
    },
    cancel() {
      this.confirm = false
      this.saveDialog = false
    },
    onFilesUploaded(files) {
      this.model.media.push(...files)
    }
  }
}
</script>
