<template>
  <div>
    <v-snackbar v-model="showError" :multi-line="true" :timeout="-1">
      {{ errorText }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="red lighten-2"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <div v-for="(message, index) in messages" :key="index">
      <v-snackbar v-model="showMessage" :multi-line="true" :timeout="5000">
        {{ message }}
        <template v-slot:action="{ attrs }">
          <v-btn text color="blue" v-bind="attrs" @click="dismiss">
            {{ $t('dismiss') }}
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data() {
    return {
      showError: false,
      showMessage: true
    }
  },
  computed: {
    ...mapGetters('messages', ['errors', 'lastError', 'messages']),

    errorText() {
      if (this.lastError) {
        return this.lastError.message
      }
      return ''
    }
  },
  watch: {
    messages(val) {
      this.showMessage = val && val.length > 0
    },
    showMessage(val) {
      if (!val) {
        this.clearMessages()
      }
    }
  },
  methods: {
    ...mapActions('messages', ['clearMessages', 'removeMessage']),
    dismiss() {
      this.clearMessages()
    }
  }
}
</script>

<style></style>
