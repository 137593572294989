<template>
  <div>
    <v-card
      :color="color"
      hover
      @dragover="dragging = true"
      @drop="dragging = false"
      @dragleave="dragging = false"
      :class="['upload-box', dragging ? 'upload-box-active' : '']"
    >
      <form enctype="multipart/form-data" novalidate>
        <input
          type="file"
          multiple
          :name="uploadFieldName"
          :disabled="isSaving"
          @change="
            filesChange($event.target.name, $event.target.files)
            fileCount = $event.target.files.length
          "
          :accept="accept"
          class="input-file"
        />
        <v-row
          v-if="!isSaving"
          align="center"
          justify="center"
          class="fill-height pt-8"
        >
          <div class="pa-3 ">
            <v-icon x-large>mdi-cloud-upload</v-icon>
          </div>
          <div class="pa-3 text-center ">
            <small>
              {{ $t('fileExplorer') }}
            </small>
          </div>
        </v-row>
        <p v-if="isSaving">{{ $t('uploading') }} {{ fileCount }} {{ $t('files') }}...</p>
      </form>
    </v-card>
  </div>
</template>

<script>
import mediaApi from '@/services/api/media'
const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3

export default {
  components: {},
  props: {
    collectionId: { type: String, required: true },
    materialId: { type: String, required: false },
    personId: { type: String, required: false },
    accept: { type: String, default: () => 'image/*' }
  },
  data() {
    return {
      dragging: false,
      fileCount: 0,
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'files'
    }
  },
  computed: {
    color() {
      return this.dragging ? 'green lighten-4' : 'grey lighten-3'
    },
    isInitial() {
      return this.currentStatus === STATUS_INITIAL
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED
    },
    uploadOptions() {
      return {
        collectionId: this.collectionId,
        materialId: this.materialId,
        personId: this.personId
      }
    }
  },
  methods: {
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL
      this.uploadedFiles = []
      this.uploadError = null
    },
    save(formData) {
      this.currentStatus = STATUS_SAVING

      mediaApi
        .uploadFormData(formData, this.uploadOptions)
        .then(x => {
          this.uploadedFiles = this.uploadedFiles.concat(x)
          this.currentStatus = STATUS_SUCCESS
          this.$emit('uploaded', x)
        })
        .catch(err => {
          this.uploadError = err.response
          this.currentStatus = STATUS_FAILED
        })
    },
    filesChange(fieldName, fileList) {
      // handle file changes
      const formData = new FormData()

      if (!fileList.length) return

      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map(x => {
        formData.append(fieldName, fileList[x], fileList[x].name)
      })

      // save it
      this.save(formData)
    }
  },
  mounted() {
    this.reset()
  }
}
</script>

<style lang="scss" scoped>
.upload-box {
  outline: 1px dashed grey; /* the dash box */
  min-height: 150px; /* minimum height */
  position: relative;
  cursor: pointer;
}
//deep//
form {
  min-height: 150px;
}

//deep//
.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 100%;
  min-height: 150px;
  position: absolute;
  cursor: pointer;
}
</style>
