class RequestQueue {
  _controller = null

  constructor() {}

  start() {
    this._controller = new AbortController()
  }

  cancelAll() {
    this._controller?.abort()
  }

  get signal() {
    if (!this._controller) {
      this.start()
    }

    return this._controller?.signal
  }
}

const queue = new RequestQueue()

export default queue
