export const APP_URL = process.env.VUE_APP_APP_URL

export const IDENTITY_SERVER_URL = process.env.VUE_APP_IDENTITY_SERVER_URL
export const IDENTITY_CLIENTID = process.env.VUE_APP_IDENTITY_CLIENTID

export const API_NAME = process.env.VUE_APP_API_NAME
export const API_URL = process.env.VUE_APP_API_URL

export const GOOGLE_MAPS_API_KEY = process.env.VUE_APP_GOOGLE_MAPS_API_KEY

export const SPD_SERVER_URL = process.env.VUE_APP_SPD_SERVER_URL
export const SPD_LICENSE_ID = process.env.VUE_APP_SPD_LICENSE_ID

export const MAX_PACKAGE_FILE_SIZE_MB =
  process.env.VUE_APP_MAX_PACKAGE_FILE_SIZE_MB || 256

export const THEME = process.env.VUE_APP_THEME
