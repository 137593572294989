import fileSize from './file-size'
import truncate from './truncate'
import dateFormat from './date-format'
import dateFormatDtg from './date-format-dtg'
import wordify from './wordify'

export default {
  install(Vue) {
    Vue.filter('truncate', truncate)
    Vue.filter('dateFormat', dateFormat)
    Vue.filter('dateFormatDtg', dateFormatDtg)
    Vue.filter('wordify', wordify)
    Vue.filter('fileSize', fileSize)
  }
}
