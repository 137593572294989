<template>
  <div v-if="model && !isLoading">
    <app-toolbar :crumbs="crumbs" />

    <v-row>
      <v-col>
        <v-card flat width="100%">
          <v-list-item three-line>
            <v-list-item-content>
              <div class="mt-2">
                {{ $t('exportedOn') }} {{ model.createdDateTime | dateFormat }}
                {{ $t('by') }}
                {{ model.createdByUserName }}
              </div>
              <div class="mt-2">{{ $t('fileName') }} {{ model.archive.fileName }}</div>
              <div class="mt-2">
                {{ $t('size') }} {{ model.archive.fileSize | fileSize }}
              </div>
              <div class="mt-2">
                <a
                  :href="model.downloadUrl.url"
                  target="_blank"
                  download
                  style="text-decoration: none;"
                >
                  <v-icon large class="mr-2" :title="$t('packageFileDownload')"
                    >mdi-download</v-icon
                  >
                </a>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import appToolbar from '@/components/layout/app-toolbar'
import collectionApi from '@/services/api/collection'
import mediaApi from '@/services/api/media'

export default {
  components: { appToolbar },
  data() {
    return {
      isLoading: true,
      collection: null,
      model: {}
    }
  },
  computed: {
    crumbs() {
      const items = [
        {
          text: this.$t('collections'),
          to: { name: 'collections-index' },
          disabled: false
        }
      ]

      if (this.collection) {
        items.push({
          text: this.collection.collectionNumber,
          to: {
            name: 'collection-detail',
            params: { collectionId: this.collectionId }
          },
          disabled: false
        })
      }

      items.push({ text: this.$t('export'), disabled: true })
      return items
    },
    collectionId() {
      return this.$route.params.collectionId
    },
    exportId() {
      return this.$route.params.exportId
    }
  },
  async mounted() {
    this.collection = await collectionApi.fetch(this.collectionId)
    const exports = await collectionApi.exports(this.collectionId)
    this.model = exports.find(x => x.id === this.exportId)
    this.model.downloadUrl = await mediaApi.getDownloadUrl(
      this.model.archive.id
    )
    this.isLoading = false
  },
  methods: {
    ...mapActions('messages', ['addMessage']),
    onCancel() {
      this.returnToCollection()
    },
    returnToCollection() {
      this.$router.push({
        name: 'collection-detail',
        params: { collectionId: this.collectionId }
      })
    },
    async download() {
      try {
        const url = this.model.downloadUrl.url
        window.open(url)
      } catch (error) {
        console.log('error', error)
        this.addError({ message: this.$t('packageError'), error })
      }
    }
  }
}
</script>

<style></style>
