<template>
  <div>
    <item-list :items="items" @view="view"> </item-list>
  </div>
</template>

<script>
import itemList from '@/components/lists/collection-items'

export default {
  components: {
    itemList
  },
  props: {
    collection: { type: Object, required: true },
    items: { type: Array, required: true }
  },
  methods: {
    view(item) {
      this.$router.push({
        name: 'collection-person-detail',
        params: { collectionId: this.collection.id, personId: item.id }
      })
    }
  }
}
</script>
