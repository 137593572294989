import apiClient from '@/services/api/clients/api-client.js'
import { API_URL } from '@/config'

const basePath = `${API_URL}/collection`

const build = function(collectionId) {
  const path = `${basePath}/${collectionId}/material`
  return apiClient.build(path)
}

export default {
  build
}
