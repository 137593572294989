import Home from '@/views/collection/index'
import Secure from '@/views/home/secure'
import Logout from '@/views/home/logout'

export default [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/secure',
    name: 'Secure',
    component: Secure,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  }
]
