<template>
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant="mini"
    app

    clipped
    :permanent="modelValue"
  >
    <v-list-item dense v-if="this.$vuetify.breakpoint.mdAndUp">
      <v-list-item-icon
        v-if="mini"
        :title="$t('menuExpand')"
        @click.stop="toggle"
      >
        <v-icon>mdi-menu</v-icon>
      </v-list-item-icon>
      <v-list-item-icon v-if="!mini" :title="$t('menuCollapse')">
        <v-btn icon @click.stop="toggle">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-list-item-icon>
      <v-list-item-content v-if="!mini">
        <v-list-item-title class="title">
          {{ organization.name }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ profile.name }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense nav>
      <v-list-item link :title="$t('collections')" :to="{ name: 'collections-index' }">
        <v-list-item-icon>
          <v-icon>mdi-folder-table</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('collections') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link :title="$t('people')" :to="{ name: 'people-index' }">
        <v-list-item-icon>
          <v-icon>mdi-briefcase-account</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('people') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link :title="$t('maps')" :to="{ name: 'maps-index' }">
        <v-list-item-icon>
          <v-icon>mdi-map</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('maps') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- <v-list-item link title="Reports">
        <v-list-item-icon>
          <v-icon>mdi-chart-pie</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Reports</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

      <v-list-item
        v-if="isOrgAdmin"
        link
        :href="memberLink"
        target="_blank"
        :title="$t('members')"
      >
        <v-list-item-icon>
          <v-icon>mdi-account-group</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('members') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        v-if="isSysAdmin"
        link
        :href="organizationLink"
        target="_blank"
        :title="$t('organizations')"
      >
        <v-list-item-icon>
          <v-icon>mdi-sitemap</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('organizations') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        link
        :title="$t('packageUploads')"
        :to="{ name: 'packages-index' }"
      >
        <v-list-item-icon>
          <v-icon>mdi-package-up</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('packageUploads') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <!-- <template v-slot:append>
      <div class="pa-2">
        <v-system-bar></v-system-bar>
      </div>
    </template> -->
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import { IDENTITY_SERVER_URL } from '@/config'
import profileMixin from '@/components/mixins/profile'

export default {
  mixins: [profileMixin],
  props: {
    modelValue: { type: Boolean, default: true }
  },
  data() {
    return {
      drawer: true,
      isCollapsed: false,
      isExpanded: false,
      mini: false,
      memberLink: `${IDENTITY_SERVER_URL}/org/members`,
      organizationLink: `${IDENTITY_SERVER_URL}/admin/organizations`
    }
  },
  computed: {
    ...mapGetters('user', ['organization'])
  },
  watch: {
    modelValue: function(newVal) {
      this.drawer = newVal
    }
  },
  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
  methods: {
    toggle() {
      if (this.mini) {
        //this.isExpanded = !this.$vuetify.breakpoint.mdAndUp
        this.isExpanded = true
        this.isCollapsed = false
        this.mini = false
      } else {
        this.isExpanded = false
        this.isCollapsed = true
        this.mini = true
      }
    },
    onResize() {
      if (
        this.isCollapsed ||
        (this.isExpanded && this.$vuetify.breakpoint.smAndDown)
      ) {
        return
      }

      this.isCollapsed = false
      this.isExpanded = false
      this.mini = this.$vuetify.breakpoint.smAndDown
    },
    drawerChange() {
      console.log(this.drawer)
      this.drawer = !this.drawer
    }
  }
}
</script>

<style></style>
