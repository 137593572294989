<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              {{ $t('exportDate') }}
            </th>
            <th class="text-left">
              {{ $t('exportFile') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id" @click="view(item)">
            <td>
              {{ item.createdByUserName }} @
              {{ item.createdDateTime | dateFormat }}
            </td>
            <td>{{ item.archive.fileName }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import collectionApi from '@/services/api/collection'

export default {
  components: {},
  props: {
    collection: { type: Object, required: true }
  },
  data() {
    return {
      items: []
    }
  },
  computed: {
    id() {
      return this.collection.id
    }
  },
  watch: {
    collection(val) {
      if (val) {
        this.loadItems()
      }
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    async loadItems() {
      if (this.id) {
        this.items = await collectionApi.exports(this.id)
      }
    },
    view(item) {
      this.$router.push({
        name: 'collection-export-detail',
        params: { collectionId: this.collection.id, exportId: item.id }
      })
    }
  }
}
</script>
