import euTheme from './eu-theme'
import mctTheme from './mct-theme'
import bemaTheme from './bema-theme'

export const themes = {
  default: mctTheme,
  eu: euTheme,
  mct: mctTheme,
  bema: bemaTheme
}
