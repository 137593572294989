//
// MCT Theme
//
export default {
  name: 'mct',
  title: 'Mobile Collection Tool',
  src: 'mct/logo.png',
  favicon: 'favicon.ico',
  location: {
    latitude: 21.4097967,
    longitude: -157.9160812,
    mgrs: '04Q FJ 128 584'
  }
}
