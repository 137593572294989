//
// EU Theme
//
const euTheme = {
  name: 'mct',
  title: 'Mobile Collection Tool',
  src: 'mct/logo.png',
  favicon: 'favicon.ico',
  location: {
    latitude: 50.4501,
    longitude: 30.5234,
    mgrs: '46Q JF 064 064'
  }
}

export default euTheme
