export default (bytes, decimalPoint) => {
  if (bytes == 0) return '0 Bytes'

  const k = 1000
  const dm = decimalPoint || 2
  const sizeLabels = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))

  const size = parseFloat((bytes / Math.pow(k, i)).toFixed(dm))

  return `${size} ${sizeLabels[i]}`
}
