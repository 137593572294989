export default str => {
  if (!str) return ''

  if (typeof str === 'string') {
    return str
      .replace(/^\w/, c => c.toUpperCase())
      .replace(/([A-Z])/g, ' $1')
      .trim()
  }

  return ''
}
