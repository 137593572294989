<template>
  <v-dialog v-model="show" class="modal" max-width="500px" persistent>
    <v-card>
      <v-card-title class="headline">
        {{ $t('diagramClose') }}
      </v-card-title>
      <v-card-text>{{ $t('diagramCloseText') }} </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn plain color="default" @click="cancel">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn ctile large color="primary" @click="close">
          {{ $t('discardChanges') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>
