<template>
  <v-row dense>
    <template v-for="(item, i) in items">
      <v-col :key="i" lg="2" md="4" sm="6">
        <v-hover v-slot="{ hover }" close-delay="200">
          <v-card
            height="100%"
            class="card-bottom-actions"
            :elevation="hover ? 8 : 2"
            :class="{ 'on-hover': hover }"
            @click.stop="view(item)"
          >
            <media-thumbnail
              v-if="item.image"
              v-model="item.image"
              :height="75"
            ></media-thumbnail>

            <v-card-title class="no-wrap">{{ item.name }}</v-card-title>
            <v-card-text>{{
              item.description | truncate(100, '...')
            }}</v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </template>
  </v-row>
</template>

<script>
import mediaThumbnail from '@/components/media/media-thumbnail'

export default {
  components: { mediaThumbnail },
  data() {
    return {
      selectedDrawing: {}
    }
  },
  props: {
    collection: { type: Object, required: true },
    items: { type: Array, required: true }
  },
  methods: {
    view(item) {
      this.$emit('view', item)
    }
  }
}
</script>
