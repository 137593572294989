import apiClient from '@/services/api/clients/api-client.js'
import { API_URL } from '@/config'

const path = `${API_URL}/people`

const client = apiClient.build(path)

client.search = options => {
  const searchPath = `${path}/search`
  return client.__api.get(searchPath, { params: options}).then(response => response.data)
}

client.all = options => {
  const url = `${path}`
  return client.get(url, { params: options}).then(response => response.data)
}

export default client
