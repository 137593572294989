<template>
  <field-card :label="label">
    <div class="field-text-area">
      {{ text }}
    </div>
  </field-card>
</template>

<script>
import FieldCard from '../field-card'

export default{
  components: { FieldCard },
  props: {
    label: {
      type: String,
      default: () => 'default'
    },
    text: {
      type: String,
      default: () => ''
    }
  },
  data(){
    return {

    }
  }
}
</script>

<style scoped>
.field-text-area {
  min-height: 5em;
}
</style>
