<template>
  <div style="height:95vh;">
    <iframe
      v-if="dataUrl"
      :src="dataUrl"
      height="100%"
      width="100%"
      :name="value.name"
    ></iframe>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: null,
      default: ''
    }
  },
  computed: {
    dataUrl() {
      if (this.value.dataUrl) {
        return this.value.dataUrl
      }
      return null
    }
  }
}
</script>
