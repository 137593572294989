<template>
  <v-app>
    <v-app-bar app dark color="#284f71" clipped-left extension-height="48">
      <v-app-bar-nav-icon @click.stop="goHome">
        <v-img
          alt="MCT Logo"
          class="shrink mr-2 ml-4"
          contain
          :src="require(`@/assets/${theme.src}`)"
          transition="scale-transition"
          width="40"
        />
      </v-app-bar-nav-icon>
      <!-- <v-app-bar-title>{{ theme.title }}</v-app-bar-title> -->
      <v-toolbar-title>{{ theme.title }}</v-toolbar-title>

      <v-spacer></v-spacer>
      <login-info />
      <template #extension v-if="this.$vuetify.breakpoint.smAndDown">
        <v-toolbar dark short dense flat color="white">
          <v-app-bar-nav-icon
            color="black"
            @click.stop="drawer = !drawer"
          ></v-app-bar-nav-icon>
          <!-- <v-toolbar-title color="black">{{ theme.title }}</v-toolbar-title> -->
        </v-toolbar>
      </template>
    </v-app-bar>

    <nav-drawer v-if="isAuthenticated" :modelValue="drawer" />

    <v-main>
      <v-container fluid>
        <transition
          name="routerAnimation"
          enter-active-class="animated faster fadeIn"
        >
          <router-view></router-view>
        </transition>
      </v-container>
    </v-main>

    <notifications></notifications>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import LoginInfo from '@/components/layout/login-info'
import NavDrawer from '@/components/layout/nav-drawer'
import Notifications from '@/components/layout/app-notifications'

export default {
  name: 'App',

  components: {
    LoginInfo,
    NavDrawer,
    Notifications
  },
  data() {
    return {
      drawer: true
    }
  },
  computed: {
    ...mapGetters('user', ['isAuthenticated']),
    ...mapGetters('theme', ['theme'])
  },
  created() {
    this.setFavicon()
  },
  mounted() {
    //this.setFavicon()
  },
  // errorCaptured() {
  //   this.snackbar = true
  //   return false
  // },
  methods: {
    goHome() {
      this.$router.push({ path: '/' })
    },
    setFavicon() {
      const favicon = document.getElementById('favicon')
      favicon.href = this.theme.favicon
    }
  }
}
</script>

<style scoped>
main div {
  height: 100%;
}

::v-deep .v-breadcrumbs__item {
  color: rgba(0, 0, 0, 0.87);
}

::v-deep .modal {
  z-index: 9999;
}

::v-deep .v-toolbar__extension {
  padding: 0 !important;
}

/* .v-toolbar__title {
  color: #000000 !important;
} */
</style>
