<template>
  <div>
    <v-form ref="form" v-model="valid">
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                :label="`${$t('name')}*`"
                v-model="model.name"
                :rules="nameRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                name="input-7-1"
                :label="`${$t('description')}*`"
                auto-grow
                v-model="model.description"
                :rules="descriptionRules"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <confirm-dialog
            v-if="allowDelete"
            :title="$t('personDelete')"
            :text="$t('personDeleteConfirm')"
            @confirm="onDelete"
          ></confirm-dialog>
          <v-spacer></v-spacer>
          <v-btn plain color="default" @click="cancel">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            tile
            large
            color="primary"
            @click.once="save"
            :disabled="!valid"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="allowMedia" class="pt-3" flat>
        <v-card-title>{{ $t('media') }}</v-card-title>
        <v-row>
          <template v-for="(media, i) in model.media">
            <v-col :key="i" md="2" sm="4">
              <v-card height="100%" width="100%" class="card-bottom-actions">
                <media-preview :value="media" :height="150"></media-preview>
                <v-card-subtitle>{{ media.name }}</v-card-subtitle>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <confirm-dialog
                    v-if="allowDelete"
                    color="theme--light"
                    icon="mdi-delete-outline"
                    :title="$t('mediaDelete')"
                    :text="$t('mediaDeleteConfirm')"
                    @confirm="deleteMediaItem(media)"
                  ></confirm-dialog>
                </v-card-actions>
              </v-card>
            </v-col>
          </template>
        </v-row>
        <v-row>
          <v-col>
            <media-upload
              :collectionId="collection.id"
              :personId="model.id"
              @uploaded="onFilesUploaded"
            ></media-upload>
          </v-col>
        </v-row>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import _ from 'lodash'
import mediaApi from '@/services/api/media'
import mediaPreview from '@/components/media/media-preview'
import mediaUpload from '@/components/media/media-upload'
import confirmDialog from '@/components/dialogs/confirmation'

export default {
  components: { confirmDialog, mediaPreview, mediaUpload },
  props: {
    collection: { type: Object, required: true },
    value: { type: Object, required: true },
    label: { type: String, default: 'Create' }
  },
  data() {
    return {
      model: {},
      nameRules: [
        v => !!v || this.$t('nameRequired'),
        v => (v && v.length <= 250) || this.$t('nameLimit')
      ],
      descriptionRules: [
        v => (v && v.length <= 5000) || this.$t('descriptionLimit')
      ],
      valid: true
    }
  },
  computed: {
    allowDelete() {
      return this.model.id
    },
    allowMedia() {
      return this.model.id
    }
  },
  mounted() {
    this.model = _.cloneDeep(this.value)
  },
  watch: {
    value(newVal) {
      if (newVal) {
        this.model = _.cloneDeep(newVal)
      } else {
        this.model = {}
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    onDelete() {
      this.$emit('delete')
    },
    save() {
      if (this.valid) {
        this.$emit('save', this.model)
      }
    },
    onFilesUploaded(files) {
      this.model.media.push(...files)
    },
    async deleteMediaItem(mediaItem) {
      await mediaApi.delete(mediaItem.id, {
        collectionId: this.collection.id,
        personId: this.model.id
      })
      this.model.media.splice(this.model.media.indexOf(mediaItem), 1)
    }
  }
}
</script>

<style></style>
