<template>
  <div v-if="model">
    <app-toolbar :crumbs="crumbs" />

    <div>
      <material-form
        v-model="model"
        :collection="collection"
        @cancel="onCancel"
        @delete="onDelete"
        @save="onSave"
      ></material-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import appToolbar from '@/components/layout/app-toolbar'
import collectionApi from '@/services/api/collection'
import materialApiBuilder from '@/services/api/material'
import materialForm from './form'

export default {
  components: { appToolbar, materialForm },
  data() {
    return {
      collection: null,
      materialApi: null,
      model: null
    }
  },
  computed: {
    crumbs() {
      const items = [
        {
          text: this.$t('collections'),
          to: { name: 'collections-index' },
          disabled: false
        }
      ]

      if (this.collection) {
        items.push({
          text: this.collection.collectionNumber,
          to: {
            name: 'collection-detail',
            params: { collectionId: this.collectionId }
          },
          disabled: false
        })
      }

      items.push({ text: 'Material', disabled: true })
      return items
    },
    collectionId() {
      return this.$route.params.collectionId
    },
    materialId() {
      return this.$route.params.materialId
    }
  },
  async mounted() {
    this.materialApi = materialApiBuilder.build(this.collectionId)
    this.collection = await collectionApi.fetch(this.collectionId)
    this.model = await this.materialApi.fetch(this.materialId)
  },
  methods: {
    ...mapActions('messages', ['addMessage']),
    onCancel() {
      this.returnToCollection()
    },
    async onDelete() {
      await this.materialApi.delete(this.materialId)
      this.addMessage({
        message: `${this.$t('material')} '${this.model.name}' ${this.$t('hasBeenDeleted')}`
      })
      this.returnToCollection()
    },
    async onSave(item) {
      await this.materialApi.put(this.materialId, item)
      this.addMessage({ 
        message: `${this.$t('material')} '${item.name}' ${this.$t('hasBeenUpdated')}`
      })
      this.returnToCollection()
    },
    returnToCollection() {
      this.$router.push({
        name: 'collection-detail',
        params: { collectionId: this.collectionId }
      })
    }
  }
}
</script>

<style></style>
