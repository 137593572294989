import Vue from 'vue'
import Vuex from 'vuex'
import collections from './modules/collections'
import messages from './modules/messages'
import people from './modules/people'
import theme from './modules/theme'
import user from './modules/user'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    title: 'Mobile Collection Tool'
  },
  mutations: {
    title(state, payload) {
      state.title = payload.title
    }
  },
  actions: {
    setTitle({ commit }, payload) {
      commit('title', payload)
      Vue.nextTick(() => {
        if (document) {
          document.title = payload.title
        }
      })
    }
  },
  modules: {
    collections,
    messages,
    people,
    theme,
    user
  }
})

export default store
