<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="valueText"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        persistent-hint
        :hint="hint"
      ></v-text-field>
    </template>

    <v-card>
      <v-date-picker v-model="date" no-title scrollable></v-date-picker>

      <div class="d-flex flex-row justify-center pa-2 pb-0">
        <v-select
          v-model="hour"
          :items="hours"
          :label="$t('hour')"
          dense
          class="mx-2"
          style="max-width:4em;"
        ></v-select>
        <v-select
          v-model="minute"
          :items="minutes"
          :label="$t('minute')"
          dense
          class="mx-2"
          style="max-width:5em;"
        ></v-select>
      </div>
      <div class="theme--light text-center text-caption">
        {{ hint }}
      </div>

      <v-card-actions class="text-right">
        <v-btn text small color="default" @click="cancelSelect">
          {{ $t('cancel') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn tile color="primary" @click="dateSelected">
          {{ $t('ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { DateTime } from 'luxon'
import { format, current } from '@/services/datetime'

export default {
  props: {
    label: { type: String, default: 'Date' },
    required: { type: Boolean, default: false },
    rules: {
      type: Array,
      default: () => {
        return []
      }
    },
    value: { type: String, default: null }
  },
  data() {
    return {
      menu: false,
      date: null,
      hour: null,
      minute: null,
      hours: [
        '00',
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23'
      ],
      minutes: ['00', '15', '30', '45'],
      valueText: ''
    }
  },
  computed: {
    datetime() {
      let dt = this.date ? DateTime.fromISO(this.date) : null

      if (dt && this.hasTime) {
        dt = DateTime.local(
          dt.year,
          dt.month,
          dt.day,
          parseInt(this.hour),
          parseInt(this.minute)
        )
      }

      return dt
    },
    datetimeValue() {
      if (this.datetime) {
        return this.hasTime ? this.datetime.toISO() : this.datetime.toISODate()
      }
      return null
    },
    hasTime() {
      return this.hour !== null && this.minute !== null
    },
    hint() {
      return `(${this.zoneName})`
    },
    zoneName() {
      return current.zoneName()
    }
  },
  watch: {
    value(newVal) {
      this.parseValue(newVal)
    }
  },
  mounted() {
    this.parseValue(this.value)
  },
  methods: {
    cancelSelect() {
      this.menu = false
    },
    dateSelected() {
      this.menu = false
      this.setValueText()
      this.$emit('input', this.datetime.setZone('utc').toString())
    },
    parseValue(newVal) {
      if (!newVal) {
        this.date = null
        this.hour = null
        this.minute = null
      } else {
        const dt = DateTime.fromISO(newVal)
        if (dt.isValid) {
          this.date = dt.toISODate()
          this.hour = dt.hour.toString().padStart(2, '0')
          this.minute = dt.minute.toString().padStart(2, '0')
        }
      }
      this.setValueText()
    },
    setValueText() {
      if (!this.datetime) {
        this.valueText = null
        return
      }

      this.valueText = format.toLocal(this.datetime)
    }
  }
}
</script>

<style></style>
