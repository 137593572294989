import imageThumbnail from '@/components/media/image/image-thumbnail.vue'
import imageViewer from '@/components/media/image/image-viewer.vue'
import documentThumbnail from '@/components/media/document/document-thumbnail.vue'
import documentViewer from '@/components/media/document/document-viewer.vue'

const install = function(Vue) {
  Vue.component('mct-image-thumbnail', imageThumbnail)
  Vue.component('mct-image-viewer', imageViewer)

  Vue.component('mct-document-thumbnail', documentThumbnail)
  Vue.component('mct-document-viewer', documentViewer)
}

export default { install }
