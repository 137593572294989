import { MAX_PACKAGE_FILE_SIZE_MB } from '@/config'

export const fileUtils = {
  maxUploadSize: MAX_PACKAGE_FILE_SIZE_MB,

  isValidUploadSize: file => {
    return file.size <= MAX_PACKAGE_FILE_SIZE_MB * 1024 * 1024
  },

  fileBytesToMB: file => {
    return Math.round(file.size / (1024 * 1024))
  }
}
