import apiClient from '@/services/api/clients/api-client'
import { API_URL } from '@/config'
import axios from 'axios'

const path = `${API_URL}/collection/package`

const client = apiClient.build(path)

const search = options => {
  const url = `${path}/search`
  const { status, ...y } = options

  const params = new URLSearchParams(y)
  for (let i = 0; i < status.length; i++) {
    params.append('status', status[i])
  }

  return client.get(url, { params: params })
}

const downloadUrl = async id => {
  const url = `${API_URL}/collection/package/${id}/downloadUrl`
  const response = await client.get(url)
  return response.data.url
}

const uploadUrl = async model => {
  const url = `${API_URL}/collection/package/uploadUrl`
  const response = await client.__api.post(url, model)
  return response.data.uploadUrl
}

const upload = async (signedUrl, file) => {
  const options = {
    headers: {
      'Content-Type': file.type
    }
  }

  return axios.put(signedUrl, file, options)
}

const uploadDirect = async (model, file) => {
  const uploadPath = `${API_URL}/collection/package`

  const formData = new FormData()
  formData.append('collectionNumber', model.collectionNumber)
  formData.append('trackingId', model.trackingId)
  formData.append('file', file)

  return client.__api.post(uploadPath, formData).then(response => response.data)
}

const process = async id => {
  const url = `${API_URL}/collection/package/${id}/process`
  const response = await client.__api.put(url, {})
  return response.data
}

export default {
  fetch: client.fetch,
  search,
  downloadUrl,
  process,
  upload,
  uploadUrl,
  uploadDirect
}
