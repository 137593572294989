var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"disable-sort":true,"items":_vm.items,"hide-default-footer":true,"item-key":"id","single-expand":true,"show-expand":"","w-full":""},scopedSlots:_vm._u([{key:"item.eventType",fn:function(ref){
var item = ref.item;
return [(item.eventType == 'Error')?_c('span',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-alert")])],1):_vm._e()]}},{key:"item.eventInstant",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.eventInstant))+" ")]}},{key:"item.metaData",fn:function(ref){
var item = ref.item;
return [(Object.keys(item.metaData).length > 0)?_c('span'):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('code',[_vm._v(_vm._s(JSON.stringify(item.metaData, null, '\n')))])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }