<template>
  <div>
    <app-toolbar :crumbs="crumbs" />
    <v-alert v-if="errors && errors.hasError" dense text color="error">
      {{ $t('mergeError') }}
      <div v-if="errors.error">
        <ul>
          <li>{{ errors.error }}</li>
        </ul>
      </div>
    </v-alert>
    <v-row>
      <v-col md="4" sm="12">
        <v-card
          class="mb-2 mx-auto"
          tile
          flat
          color="grey lighten-4"
          hover
          v-for="c in collections"
          :key="c.id"
        >
          <v-list-item>
            <v-list-item-content>
              <merge-error
                :error="errors"
                :collection="c"
                :collectionId="c.id"
              />
              <v-list-item-title
                ><router-link
                  :to="{
                    name: 'collection-detail',
                    params: { collectionId: c.id }
                  }"
                  target="_blank"
                  >{{ c.collectionNumber }}</router-link
                >
              </v-list-item-title>
              {{ c.name }}
              <div class="text-left">
                {{ c.material.length || 0 }}
                <v-icon small class="mr-2" :title="$t('material')"
                  >mdi-label-multiple</v-icon
                >
                {{ c.people.length || 0 }}
                <v-icon small :title="$t('people')">mdi-account</v-icon>
                {{ c.drawings.length || 0 }}
                <v-icon small :title="$t('drawing')">mdi-drawing</v-icon>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>

      <v-col md="8" sm="12">
        <collection-form :item="model" @cancel="onCancel" @save="onSave" />
      </v-col>
    </v-row>

    <v-overlay :value="merging">
      <v-alert dense color="primary" class="text-center" min-width="400px">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
        <div class="pt-2">
          {{ $t('collectionsMergin') }}
          <p>{{ $t('navigateDoNot') }}</p>
        </div>
      </v-alert>
    </v-overlay>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import appToolbar from '@/components/layout/app-toolbar'
import collectionApi from '@/services/api/collection'
import collectionForm from './form'
import mergeError from './merge-error.vue'

export default {
  components: {
    appToolbar,
    collectionForm,
    mergeError
  },
  data() {
    return {
      crumbs: [
        { text: this.$t('collections'), to: { name: 'collections-index' } },
        { text: this.$t('merge'), disabled: true }
      ],
      model: {},
      collections: [],
      merging: false,
      errors: null
    }
  },
  computed: {
    ids() {
      return this.$route.query.ids || []
    }
  },
  async mounted() {
    this.loadCollections()
  },
  watch: {},
  methods: {
    ...mapActions('messages', ['addError', 'addMessage']),
    onCancel() {
      this.$router.go(-1)
    },
    async loadCollections() {
      for (const id of this.ids) {
        await this.loadCollection(id)
      }

      this.collections.sort((a, b) => a.eventDateTime - b.eventDateTime)
      this.model = {
        ...this.collections[0],
        collectionNumber: null,
        name: `Merge::${this.collections[0].name}`
      }
      this.model.description = this.collections
        .map(c => `${c.collectionNumber}:\n${c.description}\n\n`)
        .join('')
    },
    async loadCollection(id) {
      const response = await collectionApi.fetch(id)
      this.collections.push({ ...response })
    },
    async onSave(model) {
      try {
        this.merging = true
        const data = {
          collectionIds: this.collections.map(c => c.id),
          eventDateTime: model.eventDateTime,
          name: model.name,
          description: model.description,
          mgrs: model.mgrs,
          latitude: model.latitude,
          longitude: model.longitude
        }

        const response = await collectionApi.merge(data)
        this.addMessage({
          message: `${data.name}: ${this.$t('hasBeenCreated')}`
        })
        this.$router.push({
          name: 'collection-detail',
          params: { collectionId: response.collection.id }
        })
      } catch (e) {
        console.log('error response', e.response)
        this.errors = e.response.data
        this.merging = false
      }
    }
  }
}
</script>

<style></style>
