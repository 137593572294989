<template>
  <div class="errors">
    <div class="error" v-for="error in errors" :key="error">
      <span v-if="error.message"> {{ error.message }}</span>
      <span v-else>{{ error.response.data }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    errors: {
      type: Array,
      required: true
    }
  }
}
</script>
