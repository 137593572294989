<template>
  <v-dialog v-model="spinner" persistent fullscreen v-if="start">
    <v-container fill-height>
      <v-layout row justify-center align-center>
        <v-progress-circular
          indeterminate
          :size="70"
          :width="7"
          color="blue"
        ></v-progress-circular>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  props: ['start'],
  data() {
    return {
      dialog: true,
      spinner: true
    }
  }
}
</script>
