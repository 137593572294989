<template>
  <div>
    <div>
      <v-row align="center" class="py-0">
        <v-col cols="12" sm="9">
          <h5>
            <v-breadcrumbs medium divider="/" :items="crumbs" class="pa-0">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="item.to"
                  :disabled="item.disabled"
                  :exact="true"
                >
                  <h2>
                    <span v-if="!item.component">{{ item.text }}</span>
                  </h2>
                  <component
                    v-if="item.component"
                    :is="item.component"
                    :value="item.value"
                    class="v-toolbar__title"
                  />
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </h5>
        </v-col>
        <v-col align="right" cols="12" sm="3">
          <slot></slot>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    crumbs: { Type: Array, default: [] }
  }
}
</script>

<style></style>
