<template>
  <v-data-table
    :headers="headers"
    :disable-sort="true"
    :items="items"
    :hide-default-footer="true"
    item-key="id"
    :single-expand="true"
    show-expand
    w-full
  >
    <template v-slot:item.eventType="{ item }">
      <span v-if="item.eventType == 'Error'">
        <v-icon color="red">mdi-alert</v-icon>
      </span>
    </template>

    <template v-slot:item.eventInstant="{ item }">
      {{ item.eventInstant | dateFormat }}
    </template>

    <template v-slot:item.metaData="{ item }">
      <span v-if="Object.keys(item.metaData).length > 0"> </span>
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <code>{{ JSON.stringify(item.metaData, null, '\n') }}</code>
      </td>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  computed: {
    headers() {
      return [
        { text: '', value: 'eventType', width: '16px' },
        { text: this.$t('timestamp'), value: 'eventInstant', width: '200px' },
        { text: this.$t('message'), value: 'message' },
        { text: '', value: 'data-table-expand' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table tbody tr:hover {
  cursor: pointer;
}

::v-deep .v-data-footer__select {
  display: none;
}
</style>
