<template>
  <span class="linked_number">
    <router-link
      :title="`search collections for ${dtg}`"
      :to="{ name: 'collections-index', query: { term: dtg } }"
      >{{ dtg }}-</router-link
    >
    <router-link
      :title="`search collections for ${mgrs}`"
      :to="{ name: 'collections-index', query: { term: mgrs } }"
      >{{ mgrs }}-</router-link
    >
    <router-link
      :title="`search collections for user ${user}`"
      :to="{ name: 'collections-index', query: { term: user } }"
      >{{ user }}</router-link
    >
  </span>
</template>
<style scoped>
.linked_number {
  display: inline-block;
}
.linked_number a {
  float: left;
  text-decoration: none;
}
</style>
<script>
export default {
  name: 'collection-number',
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dtg: '',
      mgrs: '',
      user: ''
    }
  },
  watch: {
    value(newVal) {
      this.splitNumber(newVal)
    }
  },
  mounted() {
    if (this.value) {
      this.splitNumber(this.value)
    }
  },
  methods: {
    splitNumber(number) {
      if (number) {
        this.data = number.split('-')
        this.dtg = this.data[0]
        this.mgrs = this.data[1]
        this.user = this.data[2]
      }
    }
  }
}
</script>
