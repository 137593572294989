<template>
  <div>
    <div class="d-flex justify-space-between mb-2">
      <div>
        <h2>{{ $t('packagesUploaded') }}</h2>
      </div>
      <v-card tile flat>
        <v-btn plain :to="{ name: 'package-upload' }" color="primary">
          {{ $t('upload') }}
        </v-btn>
      </v-card>
    </div>
    <div class="d-flex">
      <v-card tile flat :width="$vuetify.breakpoint.mdAndDown ? '100%' : '50%'">
        <v-form @submit.prevent="filter">
          <v-text-field
            class="mb-3"
            v-model="searchOptions.term"
            :label="$t('filter')"
            clearable
            clear-icon="mdi-close mdi-sm"
            color="default"
            hide-details="true"
            full-width
            dense
            solo
            @click:clear="clear"
          >
            <template v-slot:prepend-inner>
              <v-icon :title="$('searchesCommon')" small> mdi-text-search </v-icon>
            </template>
            <template v-slot:append>
              <v-badge
                v-model="hasFilters"
                overlap
                bordered
                :content="filterCount"
              >
                <v-icon
                :title="$t('advancedFiltersBuild')"
                  @click="showFilters = !showFilters"
                >
                  mdi-filter-variant
                </v-icon>
              </v-badge>
            </template>
          </v-text-field>
          <div v-if="showFilters">
            <label>Status</label>
            <div class="d-flex flex-wrap">
              <v-checkbox
                v-model="searchOptions.status"
                :label="$t('new')"
                value="New"
                class="mt-0 mr-4"
                hide-details="true"
              ></v-checkbox>
              <v-checkbox
                v-model="searchOptions.status"
                :label="$t('processing')"
                value="Processing"
                class="mt-0 mr-4"
                hide-details="true"
              ></v-checkbox>
              <v-checkbox
                v-model="searchOptions.status"
                :label="$t('created')"
                value="Created"
                class="mt-0 mr-4"
                hide-details="true"
              ></v-checkbox>
              <v-checkbox
                v-model="searchOptions.status"
                :label="$t('error')"
                value="Error"
                class="mt-0 mr-4"
                hide-details="true"
              ></v-checkbox>
            </div>
            <v-btn primary @click="filter" class="my-3">
              {{ $t('search') }}
            </v-btn>
          </div>
        </v-form>
      </v-card>
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="tableOptions"
      :server-items-length="serverItemsLength"
      class="elevation-1"
      item-key="id"
      :loading="isLoading"
      loading-text="Loading...."
      @click:row="view"
    >
      <template v-slot:item.createdDateTime="{ item }">
        {{ item.createdDateTime | dateFormat }} ({{ item.uploadedByUserName }})
      </template>

      <template v-slot:item.collectionId="{ item }">
        <span v-if="item.collectionId">
          <router-link
            :to="{
              name: 'collection-detail',
              params: { collectionId: item.collectionId }
            }"
            target="_blank"
            >{{ item.collectionNumber }}</router-link
          >
        </span>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="text-right">
          <v-icon
            v-if="item.fileHasBeenReceived"
            small
            class="mr-2"
            @click.stop="download(item)"
            :title="$t('packageFileDownload')"
            >mdi-download</v-icon
          >
          <v-icon
            v-if="!item.fileHasBeenReceived"
            small
            class="mr-2"
            :title="$t('waitingFile')"
            >mdi-missing</v-icon
          >
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import packageApi from '@/services/api/package'

export default {
  data() {
    return {
      advancedFilters: false,
      showFilters: false,
      isLoading: true,
      items: [],
      meta: {
        paging: {
          page: 1
        }
      },
      tableOptions: {
        page: 1,
        itemsPerPage: 25,
        sortBy: ['createdDateTime'],
        sortDesc: [true]
      },
      searchOptions: {
        status: []
      }
    }
  },
  computed: {
    headers() {
      return [
        { text: this.$t('number'), value: 'collectionNumber' },
        { text: this.$t('status'), value: 'status' },
        { text: this.$t('uploaded'), value: 'createdDateTime' },
        { text: this.$t('collection'), value: 'collectionId' },
        { text: '', value: 'actions', sortable: false }
      ]
    },
    serverItemsLength() {
      return this.meta.paging.totalItems
    },
    filterCount() {
      return this.searchOptions.status.length
    },
    hasFilters() {
      return this.filterCount > 0
    }
  },
  watch: {
    tableOptions: {
      handler(val) {
        this.searchOptions = {
          ...this.searchOptions,
          ...{
            page: val.page,
            orderBy: val.sortBy[0],
            isAscending: !val.sortDesc[0]
          }
        }
        this.search()
      },
      deep: true
    }
  },
  async mounted() {
    await this.search()
  },
  methods: {
    ...mapActions('messages', ['addError']),
    async search() {
      try {
        this.isLoading = true
        const response = await packageApi.search(this.searchOptions)
        this.items = response.data.results
        this.meta = response.data.meta
        this.tableOptions.page = this.meta.paging.currentPage
      } finally {
        this.isLoading = false
      }
    },
    filter() {
      this.searchOptions.page = 1
      this.search()
    },
    async clear() {
      if (this.searchOptions.term) {
        const options = {}
        if (this.advancedFilters) {
          options.includeArchived = this.includeArchived
        }
        await this.all(options)
        this.searchOption = {}
      }
    },
    view(item) {
      this.$router.push({
        name: 'package-detail',
        params: { packageId: item.id }
      })
    },
    async download(item) {
      try {
        const url = await packageApi.downloadUrl(item.id)
        window.open(url)
      } catch (error) {
        console.log('error', error)
        this.addError({ message: this.$t('packageError'), error })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table tbody tr:hover {
  cursor: pointer;
}

::v-deep .v-data-footer__select {
  display: none;
}
</style>
