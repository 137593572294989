import FieldCard from '@/components/fields/field-card/index.vue'
import TextArea from '@/components/fields/text-area/index.vue'
import TextField from '@/components/fields/text-field/index.vue'

const install = function(Vue) {
  Vue.component('flns-field-card', FieldCard)
  Vue.component('flns-text-area', TextArea)
  Vue.component('flns-text-field', TextField)
}

export default install
