import axios from 'axios'
import apiClient from '@/services/api/clients/api-client.js'
import { API_URL } from '@/config'
import AuthService from '@/services/auth.service'
import queue from './queue'
import cache from './cache'

const path = `${API_URL}/media/`

const client = apiClient.build(path)

client.__api.interceptors.request.use(async config => {
  config.signal = queue.signal
  return config
})

const getDownloadUrl = async mediaId => {
  const url = `${path}${mediaId}/downloadUrl`
  return client.__api.get(url).then(response => response.data)
}

const dataURItoBlob = dataURI => {
  const byteString = atob(dataURI.split(',')[1])
  const mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0]

  const arrayBuffer = new ArrayBuffer(byteString.length)
  const _ia = new Uint8Array(arrayBuffer)
  for (let i = 0; i < byteString.length; i++) {
    _ia[i] = byteString.charCodeAt(i)
  }

  const dataView = new DataView(arrayBuffer)
  const blob = new Blob([dataView], { type: mimeString })
  return blob
}

const upload = function(file, options) {
  const uploadPath = buildUploadPath(options)

  const formData = new FormData()
  formData.append('files', file)

  return client.__api.post(uploadPath, formData).then(response => response.data)
}

const uploadFormData = function(formData, options) {
  const uploadPath = buildUploadPath(options)

  return client.__api.post(uploadPath, formData).then(response => response.data)
}

const deleteItem = function(id, options) {
  let path = buildUploadPath(options)
  path = path.concat(`/${id}`)

  return client.__api.delete(path).then(response => response.data)
}

function buildUploadPath(options) {
  let uploadPath = `${API_URL}`
  if (options.collectionId) {
    uploadPath = uploadPath.concat(`/collection/${options.collectionId}`)
  }
  if (options.materialId) {
    uploadPath = uploadPath.concat(`/material/${options.materialId}`)
  }
  if (options.personId) {
    uploadPath = uploadPath.concat(`/person/${options.personId}`)
  }

  uploadPath = uploadPath.concat('/media')

  return uploadPath
}

/*------------------------------------------------------
* Retrieve binary version of a file
------------------------------------------------------*/
const imageClient = axios.create({
  baseURL: path,
  responseType: 'arraybuffer'
})

imageClient.interceptors.request.use(async config => {
  const accessToken = await AuthService.getAccessToken()

  if (!accessToken) {
    throw new Error('A valid access token is required to query images')
  }

  config.headers.Authorization = `Bearer ${accessToken}`
  config.signal = queue.signal
  return config
})

const getBase64Url = async (mediaId, options, config) => {
  try {
    options = options || {}
    config = config || {}

    const mediaItemPath = `${path}${mediaId}`

    if (!options.noCache) {
      const cachedValue = await cache.fetch(mediaItemPath)
      if (cachedValue) {
        return cachedValue
      }
    }

    options.noRedirects = true

    let response = await imageClient.get(mediaItemPath, {
      ...config,
      params: { ...options }
    })

    //Handle manual redirects
    if (response.status === 204 && response.headers['location']) {
      const location = response.headers['location']

      const redirectClient = axios.create({
        baseURL: location,
        responseType: 'arraybuffer'
      })

      response = await redirectClient.get(location, { ...config })
    }

    const contentType = response.headers['content-type']

    if (response.status === 200) {
      if (options.binary) {
        return response.data
      }

      const buffer = Buffer.from(response.data, 'binary')
      const base64 = buffer.toString('base64')
      const result = `data:${contentType};base64,${base64}`

      cache.put(mediaItemPath, result, contentType)
      return result
    }

    throw new Error(`Failed to load image, status: ${response.status}`)
  } catch (err) {
    if (axios.isCancel(err)) {
      return ''
    } else {
      throw err
    }
  }
}

export default {
  dataURItoBlob,
  getBase64Url,
  delete: deleteItem,
  upload,
  uploadFormData,
  queue,
  getDownloadUrl
}
