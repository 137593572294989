<template>
  <v-menu bottom left offest-y v-model="showMenu">
    <template v-slot:activator="{ on, attrs }">
          <v-btn plain v-bind="attrs" v-on="on">
            <!-- <v-icon x-small>mdi-account</v-icon> -->
            <span class="pl-1 pr-1">{{ language }}</span>
            <v-icon x-small>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item @click.stop="selectLanguage(lang)" v-for="lang in availableLanguages" :key="lang">

            <v-list-item-content>
              <v-list-item-title class="text-uppercase">{{lang}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </v-list>
  </v-menu>
</template>

<script>
import { languages } from '@/plugins/i18n'
import { mapGetters, mapActions } from 'vuex'

export default {
  data: () => ({
    showMenu: false,
    language: 'en'
  }),
  computed: {
    ...mapGetters('user', ['locale']),
    availableLanguages() {
      return languages
    }
  },
  mounted() {
    this.language = this.locale
  },
  methods: {
    ...mapActions('user', ['setLocale']),
    selectLanguage(selected) {
      this.showMenu = false
      this.language = selected
      localStorage.userLang = selected
      this.setLocale(selected)
    }
  }
}
</script>
