<template>
  <div>
    <app-toolbar :crumbs="crumbs" />

    <collection-form :item="model" @cancel="onCancel" @save="onSave" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import appToolbar from '@/components/layout/app-toolbar'
import collectionApi from '@/services/api/collection'
import collectionForm from './form'

export default {
  components: {
    appToolbar,
    collectionForm
  },
  data() {
    return {
      model: {}
    }
  },
  computed: {
    crumbs() {
      return [
        { text: this.$t('collections'), to: { name: 'collections-index' } },
        { text: this.$t('create'), disabled: true }
      ]
    }
  },
  methods: {
    ...mapActions('messages', ['addMessage']),
    onCancel() {
      this.$router.go(-1)
    },
    async onSave(model) {
      const response = await collectionApi.post(model)
      this.addMessage({
        message: `${this.$t('collection')} 
          ${model.name} 
          ${this.$t('hasBeenCreated')}`
      })
      this.$router.push({
        name: 'collection-detail',
        params: { collectionId: response.data.id }
      })
    }
  }
}
</script>

<style></style>
