<template>
  <div>
    <v-dialog v-model="dialog" fullscreen>
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex flex-column" v-bind="attrs" v-on="on">
          <v-text-field
            v-model="displayLocation"
            :label="$t('location')"
            prepend-icon="mdi-map-marker"
            readonly
            v-bind="attrs"
          />
          <map-preview
            v-if="hasValue"
            :width="width"
            :height="height"
            :mgrs="innerLocation.mgrs"
            :lat="innerLocation.latitude"
            :long="innerLocation.longitude"
          />
        </div>
      </template>
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t('locationSelect') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="save">
              {{ $t('select') }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-alert
              v-model="hasError"
              class="text-center"
              color="red"
              dense
              dismissible
              text
              type="warning"
            >
              {{ error }}
            </v-alert>
            <v-form ref="searchForm" @submit.prevent="onSearch">
              <v-text-field
                ref="searchBox"
                v-model="search"
                :label="$t('locationSelected')"
                :placeholder="$t('locationType')"
                persistent-hint
                :hint="$t('enterCoordinates')"
              />
            </v-form>

            <div ref="googleMap" id="map"></div>

            <div v-if="innerLocation" class="d-flex justify-space-between">
              <small
                >Lat/Long: {{ this.innerLocation.latitude }},{{
                  this.innerLocation.longitude
                }}</small
              >
              <small>MGRS: {{ this.innerLocation.mgrs }}</small>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import locationService from '@/services/location'
import mapPreview from './preview'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    mapPreview
  },
  props: {
    format: {
      type: String,
      default: 'mgrs',
      validator: val => ['mgrs', 'latlng'].includes(val)
    },
    label: { type: String, default: this },
    width: { default: 300 },
    height: { default: 150 },
    value: { type: Object, required: false }
  },
  data() {
    return {
      dialog: false,
      innerLocation: {},
      hasError: false,
      error: null,
      search: null,

      googleMaps: null,
      map: null,
      marker: null,
      placesService: null
    }
  },
  mounted() {
    this.label = this.$t('location')
  },
  computed: {
    ...mapGetters('user', ['location']),
    defaultLocation() {
      if (this.location) {
        return { ...this.location }
      }
      return { ...locationService.defaultLocation() }
    },
    displayLocation() {
      if (!this.hasValue) {
        return ''
      }

      switch (this.format) {
        case 'mgrs':
          return this.value.mgrs
        default:
          return this.value.latitude
            ? `${this.value.latitude},${this.value.longitude}`
            : ''
      }
    },
    hasValue() {
      return this.value && (this.value.latitude || this.value.mgrs)
    },
    mapOptions() {
      return {
        center: {
          lat: this.innerLocation.latitude,
          lng: this.innerLocation.longitude
        },
        zoom: locationService.zoom,
        fullscreenControl: false,
        streetViewControl: false
      }
    }
  },
  watch: {
    async dialog(newVal) {
      if (newVal) {
        if(!this.location?.latitude) {
          await this.setLocation()
        }

        this.innerLocation = this.defaultLocation

        await this.initializeMap()
        this.createMarker()
      }
    },
    innerLocation: {
      deep: true,
      handler() {
        if (!this.innerLocation) {
          return
        }

        switch (this.format) {
          case 'mgrs':
            this.search = this.innerLocation.mgrs
            break
          default:
            this.search = `${this.innerLocation.latitude},${this.innerLocation.longitude}`
        }
      }
    },
    value(val) {
      if (val) {
        this.innerLocation = Object.assign(this.innerLocation, val)
      }
    }
  },
  /* mounted() {
  }, */
  methods: {
    ...mapActions('user', ['setLocation']),
    async initializeMap() {
      if (this.map) {
        return
      }

      this.googleMaps = await locationService.googleMaps()
      this.map = new this.googleMaps.Map(
        document.getElementById('map'),
        this.mapOptions
      )
      this.map.addListener('click', this.selectLocation)
      this.placesService = new this.googleMaps.places.PlacesService(this.map)
    },
    selectLocation(mapMouseEvent) {
      this.innerLocation.latitude = mapMouseEvent.latLng.lat()
      this.innerLocation.longitude = mapMouseEvent.latLng.lng()
      this.innerLocation.mgrs = locationService.getMgrs(this.innerLocation)

      if (this.marker) {
        const p = {
          lat: this.innerLocation.latitude,
          lng: this.innerLocation.longitude
        }
        this.map.setCenter(p)
        this.marker.setPosition(p)
      } else {
        this.createMarker()
      }
    },
    createMarker() {
      if (this.map) {
        this.map.setCenter({
          lat: this.innerLocation.latitude,
          lng: this.innerLocation.longitude
        })

        this.marker = new this.googleMaps.Marker({
          position: {
            lat: this.innerLocation.latitude,
            lng: this.innerLocation.longitude
          },
          map: this.map,
          title: this.$t('locationSelected'),
          draggable: true
        })

        this.marker.addListener('dragend', this.selectLocation)
      }
    },
    onSearch() {
      if (!this.search) {
        return
      }

      const p = locationService.isLatLong(this.search)
      if (p) {
        this.selectLocation({
          latLng: { lat: () => p.latitude, lng: () => p.longitude }
        })
        this.items = [{ description: this.search }]
        return
      }

      if (locationService.isMgrs(this.search)) {
        const p = locationService.getLatLng(this.search)
        this.selectLocation({
          latLng: { lat: () => p.latitude, lng: () => p.longitude }
        })
        this.items = [{ description: this.search, place_id: this.search }]
        return
      }

      const request = {
        query: this.search,
        fields: ['name', 'geometry']
      }

      this.placesService.findPlaceFromQuery(request, (results, status) => {
        if (status === 'OK' && results) {
          this.selectLocation({ latLng: results[0].geometry.location })
        } else {
          this.hasError = true
          this.error = `Could not find location ${this.search}. ${status}`
        }
      })
    },
    save() {
      this.$emit('input', { ...this.innerLocation })
      this.cleanup()
      this.dialog = false
    },
    close() {
      this.cleanup()
      this.dialog = false
    },
    cleanup() {
      this.innerLocation = { ...this.value }
      this.map = null
    },
    async getCurrentLocation(){
      const browserLocaion = await locationService.browserLocation()
      if(browserLocaion){
        this.setLocation(browserLocaion)
      }
      else{
        console.log('can not access browser location')
      }
    }
  }
}
</script>

<style scoped>
#map {
  width: 100%;
  height: 100%;
  min-height: 600px;
}
</style>
