import AuthService from '@/services/auth.service.js'
import locationApi from '@/services/location'
import i18n, { selectedLocale } from '@/plugins/i18n'

export default {
  namespaced: true,
  state: {
    profile: null,
    locale: selectedLocale
  },
  mutations: {
    setUser(state, payload) {
      state.profile = payload.profile
    },
    setLocale(state, payload) {
      state.locale = payload.locale
    },
    setLocation(state, payload) {
      state.location = payload
    }
  },
  actions: {
    async setUser({ commit }) {
      AuthService.getUser().then(user => {
        const profile = user ? user.profile : null
        commit('setUser', { profile })
      })
    },
    async clearUser({ commit }) {
      commit('setUser', { profile: null })
    },
    async setLocation({ commit }, payload) {
      if (payload) {
        commit('setLocation', payload)
      }

      const browserLocation = await locationApi.browserLocation()
      if (browserLocation) {
        const { latitude, longitude } = browserLocation
        commit('setLocation', { latitude, longitude })
      } else {
        commit('setLocation', locationApi.defaultLocation())
      }
    },
    async setLocale({ commit }, locale) {
      i18n.locale = locale
      commit('setLocale', { locale: locale })
    }
  },
  getters: {
    profile: state => {
      return state.profile || {}
    },
    organization: state => {
      if (state.profile) {
        return {
          id: state.profile['mct-orgid'],
          name: state.profile['mct-orgname']
        }
      }
      return {}
    },
    isInRole: state => role => {
      if (state.profile && state.profile.role) {
        return state.profile.role.indexOf(role) > -1
      }
      return false
    },
    isAuthenticated: state => {
      return (
        state.profile !== null &&
        state.profile.user !== null &&
        !state.profile.expired
      )
    },
    userCode: state => {
      if (state.profile) {
        return state.profile['mct-usercode']
      }
      return ''
    },
    userId: state => {
      if (state.profile) {
        return state.profile['sub']
      }
      return ''
    },
    location: state => {
      return state.location
    },
    locale: state => {
      if (state.locale) {
        return state.locale
      }

      if (state.profile && state.profile['locale']) {
        return state.profile['locale']
      }

      return null
    }
  }
}
